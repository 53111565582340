@import "~antd/dist/antd.less";
@import "./theme.less";

html, body {
  height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

body {
  font-size: 14px;
  background-color: #fafafa;
}

.customTabs {
  padding-top: 38px;
}

.customTabs .ant-tabs-bar.ant-tabs-top-bar {
  z-index: 10;
  position: fixed;
  top: 139px;
  padding: 0 40px;
  width: 100%;
  background: #ffffff;
  border-bottom: none;
  box-shadow: 0px 1px 0px 0px #e6e6e6;
}

.customList.ant-list {
  padding-left: 2rem;
  margin-bottom: 1.5rem;
}

.customList.ant-list .ant-list-items > .ant-list-item {
  border: none;
  padding-bottom: 0;
}
